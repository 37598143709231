<template>
  <div class="d-flex justify-center">
    <div class="app-empty-list">
      <template v-if="!networkError">
        <img
          class="app-empty-list__background-img"
          src="@/assets/icons/splash-icon.svg"
          height="300"
          alt="empty list">

        <div class="d-flex flex-column gap-2">
          <h1 class="fs-42 font-weight-bold text-center heading">
            {{ mainHeading }}
          </h1>

          <h2 class="fs-16 text-center subheading">
            {{ subHeading }}
          </h2>
        </div>

        <div class="d-flex flex-wrap justify-center gap-4">
          <v-btn
            v-if="from === 'community'"
            class="elevation-0"
            color="blue"
            dark
            @click="startSearch">
            Start Searching
          </v-btn>

          <router-link
            v-if="from === 'pages'"
            :to="{ name: 'community-collections', params: { wId: activeWorkspaceId, id: 'community' }}"
            class="">
            <v-btn
              class="white--text"
              color="blue"
              elevation="0">
              View Your Community
            </v-btn>
          </router-link>

          <app-btn
            v-if="from === 'projects'"
            :access="canCreateProject"
            :show-tooltip="!canCreateProject"
            btn-class="white--text"
            color="blue"
            data-test="create_project_button"
            elevation="0"
            bottom
            @click="canCreateProject.allowed && $store.commit('showModalAction', 'showCreateProjectModal')">
            CREATE PROJECT
          </app-btn>

          <v-btn
            v-if="hasTutorial"
            class="elevation-0"
            color="green"
            dark
            @click="openTutorial">
            View Tutorials
          </v-btn>
        </div>
      </template>
      <h1
        v-else
        class="text-center">
        Your workspace is loading. Please wait.
      </h1>
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';

import {
  EventBus,
  START_NEW_COMMUNITY_SEARCH,
} from '@/utils/eventBus';

export default {
  name: 'AppEmptyList',
  props: {
    from: {
      type: String,
      default: 'libraries',
    },
  },
  computed: {
    ...mapState(['networkError']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('UserRoles', ['canCreateProject']),
    hasTutorial() {
      return ['projects', 'collections', 'community'].includes(this.from);
    },
    mainHeading() {
      switch (this.from) {
      case 'projects':
        return 'You don\'t have any Projects yet';
      case 'libraries':
        return 'You don\'t have any Libraries yet';
      case 'library-collections':
        return 'You haven\'t added any collections yet';
      case 'collections':
        return 'You don\'t have any Collections yet';
      case 'community':
        return 'You haven\'t followed anything yet!';
      default:
        return 'You haven\'t followed any Pages yet';
      }
    },
    subHeading() {
      switch (this.from) {
      case 'projects':
        return 'Let\'s start with your first project';
      case 'libraries':
        return 'Create one and add some collections to it!';
      case 'library-collections':
        return 'Go follow some collections into this library!';
      case 'collections':
        return 'Create one and add some products to it!';
      case 'community':
        return `Don't worry, changing that is super simple! Just start searching
        the community to find manufacturers and collections that you would like
        to follow and envision using their products in your next projects.`;
      default:
        return 'Go to the community to follow pages.';
      }
    },
  },
  methods: {
    openTutorial() {
      let link;
      switch (this.from) {
      case 'projects':
        link = 'https://sourcery.tawk.help/category/managing-your-projects';
        break;
      case 'collections':
        link = 'https://sourcery.tawk.help/category/managing-your-collections';
        break;
      case 'community':
        link = 'https://sourcery.tawk.help/category/managing-your-community';
        break;
      }

      window.open(link, '_blank');
    },
    startSearch() {
      EventBus.$emit(START_NEW_COMMUNITY_SEARCH);
    },
  },
};
</script>
<style scoped lang="scss">
.app-empty-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  max-width: 60%;
  width: 100%;
  &__background-img {
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 80%;
      height: auto;
    }
  }

  .heading {
    line-height: 62px;
  }

  .subheading {
    color: #333;
    line-height: 24px;
  }
}
</style>
